import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/Home",
        component: () => import('@/views/Home'),
    },
    {
        path: '/',
        redirect: "/Introduce",
        component: () => import('@/views/introduce/introduce')
    },
    {
        path: '/official',
        name: "Home",
        component: () => import('@/views/Home'),
        meta: {
            title: '和裕开放平台'
        }
    },
    {
        path: '/Apply',
        name: "Apply",
        component: () => import('@/views/apply/apply'),
        meta: {
            title: '达人入驻'
        },
    },
    {
        path: '/Introduce',
        name: "Introduce",
        component: () => import('@/views/introduce/introduce'),
        meta: {
            title: '公司介绍'
        },
    }
]

const router = new VueRouter({
    routes
})
router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next()
})

export default router